<template>
  <div class="bg" :style="{'backgroundImage':'url('+ menu1 +')'}">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import menu1 from "@/assets/image/conference/pic_2@2x.png"

export default {
  name: "nineteenPage",
  components: {ClassBut},
  data() {
    return {
      menu1: menu1
    }
  },

  methods: {
    forward() {
      this.$router.push({
        name: 'PageTenth',
        query: this.$route.query,
      })
    },

  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

</style>
